@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}


.gap-0 .css-19kzrtu {
  padding-top: 0;
}

.heder-tab button {
  padding-bottom: 10px;

}

.heder-tab span.MuiTabs-indicator {
  display: none;
}

.heder-tab button[aria-selected="true"]::after {
  opacity: 1;
  visibility: visible;
}

.heder-tab button[aria-selected="true"] {
  background: #62b5ab;
  color: #fff;
}

.heder-tab button:after {
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 8px solid transparent;
  border-top: 9px solid #62b5ab;
  content: "";
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.product-img {
  max-width: 80px;
}

.warrenty-box {

  background: #f7fbfb;
}

.warrenty-disc {

  flex: 1;
}

.item-title {
  font-weight: 600;
  font-size: 22px;
}

.to-green-700 {
  font-weight: 600;
  color: #80beb5;
}

.cut-price {
  text-decoration: line-through;
}

.real-price {
  font-size: 16px;
}

.spc_title {
  background: #eeeeee;
}

.spc_title h3 {
  padding: 10px 15px;
  font-weight: 600;
}

.spc_title p {
  background: #fff;
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  /* font-weight: 600; */
  color: #80beb5;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover, .nav-tabs .nav-link.nav-active-desc, .nav-tabs .nav-link.nav-active-spec {
  border: 0;
  border-bottom: 2px solid #80beb5;
}

ul#myTab {
  margin-bottom: 15px;
}

.nav-tabs .nav-link {
  color: #000;
}

.nav-tabs .nav-tabs .nav-link.nav-active-desc, .nav-tabs .nav-link.nav-active-spec {
  font-weight: 600;
}

.spc_title p span {
  font-weight: 600;
  color: #000;
  float: right;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-top: 0;
   border-left: 0;
  border-radius: 0;
}
.carousel-item img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
}

.my-slider img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .resp_col {
    flex: 0 0 auto;
    padding-bottom: 10px;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png") no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

/* page container width */
.page-wrap-container {
  max-width: calc(100% - 18rem);
}

.page-wrap-container.hide-side-nav {
  max-width: 100%;
}

/* confirm dialouge css */
.e-confirm-dialog.e-dialog.e-popup {
  margin: 30px auto;
  width: 350px;
}